function toggleMenu() {
  const duration = 450;
  $("#side-menu").toggle("slide", { direction: "left" }, duration);
  $("#side-menu-overlay").fadeToggle(duration);
}

const currentUrl = location.href.toLowerCase();
const domain =
  location.protocol +
  "//" +
  location.hostname +
  (location.port ? ":" + location.port : "");

const navbarMenuItems = [
  { directory: "", item: "#nav-learner" },
  { directory: "/questions", item: "#nav-questions" },
  { directory: "/textbook", item: "#nav-textbook" },
  { directory: "/lectures", item: "#nav-lectures" },
  { directory: "/instructor/dashboard", item: "#nav-instructor-dashboard" },
  { directory: "/instructor/assignments", item: "#nav-instructor-assignments" },
  { directory: "/instructor/stats", item: "#nav-instructor-stats" }
  // { directory: '/instructor/curriculum',  item: '#nav-instructor-curriculum' }
];
// const learnerDirectories =  [ '', '/questions', '/textbook', '/lectures', '/instructor/dashboard', '/instructor/assignments', '/instructor/stats', '/instructor/curriculum' ];
// const learnerItems =        [ '#nav-dashboard', '#nav-questions', '#nav-textbook', '#nav-lectures', '#nav-instructor-dashboard', '#nav-instructor-assignments', '#nav-instructor-stats', '#nav-instructor-curriculum' ];
// const instructorDirectories = [ '/instructor/dashboard', '/instructor/assignments', '/instructor/stats', '/instructor/curriculum' ];
// const instructorItems = [ '#nav-instructor-dashboard', '#nav-instructor-assignments', '#nav-instructor-stats', '#nav-instructor-curriculum' ];

const selectors = ["#main-nav li", ".sidenav tr"];

refreshNavbar();

function refreshNavbar() {
  var nav = document.querySelector("#main-nav");
  //check to make sure nav is not null, else there will be an error in the console when user is logging in
  var navItems = nav ? [...nav.querySelectorAll(".nav-item")] : null;
  //ensure that navlinks is not null before looping over them
  navItems &&
  navItems.forEach(li => {
      var link = li.querySelector('a')
      // we don't care if these urls differ by a trailing forward-slash
      const trailingSlashRegex = /\/$/;
      const href = link.href.replace(trailingSlashRegex, '');
      if (href === currentUrl.replace(trailingSlashRegex, '')) {
         $(li).addClass("active")
         link.style.textDecoration = "none";
      }
    });
}

//!PREVIOUS NAVLINK LOGIC
// selectors.forEach(function (selector) {
//    $(selector).each(function (i) {
//       if (i >= navbarMenuItems.length) {
//          return;
//       }
//       $(this).removeClass("active");
//       if (i === 0) {
//          console.log('curr', currentUrl)
//          console.log('domain',)
//          if (currentUrl === domain + "/") {
//             $(navbarMenuItems[i].item).addClass("active");
//             return false;
//          }
//       } else {
//          if (currentUrl.indexOf(domain + navbarMenuItems[i].directory) > -1) {
//             console.log("found item " + JSON.stringify(navbarMenuItems[i]));
//             $(navbarMenuItems[i].item).addClass("active");
//             return false;
//          }
//       }
//    });
// });
